<template>
  <div class="real-estate">
    <a v-if="!isTablet" class="button fixed-button" href="#contact-form"> Hébergez un site </a>
    <section class="hero">
      <h1>
        Hébergez un site <br />
        d’Electrify Canada
      </h1>
      <p>
        Nos bornes de recharge en courant continu (CC) offrent certaines des vitesses de recharge les plus rapides sur
        le marché aujourd’hui (ultrarapide [150 kW] et hyper rapide [350 kW])
      </p>
      <section class="flex">
        <p>Attirez de nouveaux clients et de nouvelles possibilités de revenus</p>
        <p>Explorez le réseau à l’avant-garde de l’offre de bornes ultrarapides (150 kW) et hyper rapides (350 kW).</p>
        <p>Centre d’assistance à la clientèle 24 h sur 24, 7 jours sur 7</p>
      </section>
      <a href="#contact-form" class="button">demandez d'héberger une station de recharge ultrarapide</a>
    </section>
    <section class="body text">
      <div class="">
        <div class="lede lede--reverse">
          <div class="column image">
            <img
              loading="lazy"
              alt=""
              src="@/assets/images/RealEstate/person-using-charger.png"
            />
          </div>
          <div class="column">
            <h2>
              Avantages pour l’hôte<br />
              du site
            </h2>
            <p>
              Devenir l’hôte d’un site vous permet d’attirer de nouveaux clients et de vous placer à l’avant-garde du
              paysage de la consommation en constante évolution.
            </p>
            <ul class="list-electric-charger">
              <li>
                <strong>81% </strong>des propriétaires de VE déclarent que la disponibilité d’une borne de recharge les
                rend plus fidèles à une entreprise<sup>1</sup>.
              </li>
              <li><strong>Diversifiez </strong>les commodités offertes dans votre commerce de détail.</li>
              <li><strong>Rentabilisez </strong>votre propriété de façons non exploitées auparavant.</li>
              <li>
                <strong>Profitez </strong>des relations avec les fabricants d’automobiles en attirant des VE de toutes
                sortes à votre site.
              </li>
            </ul>
          </div>
        </div>
        <div class="cards-container">
          <h2>Critères d’un site optimal</h2>
          <section class="l-three-col">
            <div class="card">
              <img
                loading="lazy"
                src="@/assets/images/RealEstate/electric-business.svg?external"
                alt=""
              />
              <h3>Proximité<br />de commerces<br />de détail</h3>
            </div>
            <div class="card">
              <img
                loading="lazy"
                src="@/assets/images/RealEstate/electric-pin.svg?external"
                alt=""
              />
              <h3>Espaces de<br />stationnement</h3>
            </div>
            <div class="card">
              <img
                loading="lazy"
                src="@/assets/images/RealEstate/electric-plug.svg?external"
                alt=""
              />
              <h3>Proximité<br />de l’énergie</h3>
            </div>
          </section>
        </div>
        <div class="lede">
          <div class="column image">
            <img
              loading="lazy"
              alt=""
              src="@/assets/images/RealEstate/construction.png"
            />
          </div>
          <div class="column">
            <h2>Frais d’installation et d’entretien</h2>
            <p>
              <strong>Electrify Canada paie 100 % de l’investissement initial en capital </strong>pour la conception et
              l’installation des nouvelles stations de recharge ainsi que les coûts d’entretien et de maintenance, et
              après la signature des contrats et l’installation du matériel, un appel téléphonique suffit à nous
              joindre.
            </p>
          </div>
        </div>
        <div class="lede lede--reverse">
          <div class="column image map">
            <img
              loading="lazy"
              alt="Carte affichant les emplacements des stations de recharge au Canada"
              src="@/assets/images/RealEstate/chargers-map.png"
            />
            <ul>
              <li>Stations de recharge ultrarapide en CC d’Electrify Canada en avril 2023</li>
            </ul>
          </div>
          <div class="column">
            <h2>Stations de recharge pour véhicules électriques Electrify Canada</h2>
            <p>
              Devenez un chef de file de l’industrie qui appuie l’énergie propre. Rejoignez les chefs de file du domaine
              des dépanneurs, du commerce de détail et de l’épicerie sur le réseau Electrify Canada.
            </p>
          </div>
        </div>
      </div>
    </section>
    <form id="contact-form" @submit.prevent="validate" ref="form" :aria-label="$t('contactUs')">
      <section>
        <h2>Vous souhaitez rejoindre la famille Electrify Canada en hébergeant une station de recharge ultrarapide?</h2>
        <p>
          Mettez-vous au travail. Remplissez le formulaire ci-dessous pour communiquer avec nous et lancer la
          conversation.
        </p>
      </section>
      <div class="form-wrap">
        <p aria-hidden="true" class="form-legend">Un astérisque (<span>*</span>) indique un champ obligatoire.</p>
        <div class="input-field">
          <label ref="firstname" for="first_name">Prénom <span class="require-mark" aria-hidden="true">*</span></label>
          <input id="first_name" maxlength="39" name="first_name" v-model="firstName" type="text" required />
        </div>
        <div class="input-field">
          <label ref="lastname" for="last_name"
            >Nom de famille <span class="require-mark" aria-hidden="true">*</span></label
          >
          <input
            id="last_name"
            maxlength="39"
            name="last_name"
            type="text"
            pattern="^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?: +[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*$"
            title="This field only accepts letters and special characters (no trailing spaces)"
            v-model="lastName"
            required
          />
        </div>
        <div class="input-field">
          <label ref="email" for="email"
            >Courriel de l’entreprise <span class="require-mark" aria-hidden="true">*</span></label
          >
          <input
            id="email"
            maxlength="80"
            name="email"
            type="text"
            v-model="email"
            pattern="[a-zA-Z0-9_@./#&+-]+(?:\.[a-zA-Z0-9_@./#&+-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])"
            title="Please enter the correct email address"
            required
          />
        </div>
        <div class="input-field">
          <label ref="companyname" for="company_name"
            >Nom de l’entreprise <span class="require-mark" aria-hidden="true">*</span></label
          >
          <input id="company_name" maxlength="200" name="company_name" type="text" v-model="company" required />
        </div>
        <div class="input-field">
          <label ref="title" for="title"
            >Appellation d’emploi <span class="require-mark" aria-hidden="true">*</span></label
          >
          <input id="title" maxlength="128" name="title" type="text" v-model="title" required />
        </div>
        <div class="input-field">
          <label for="phone">Numéro de téléphone<span class="require-mark" aria-hidden="true">*</span></label>
          <input
            id="phone"
            maxlength="10"
            name="phone"
            v-model="phone"
            type="text"
            pattern="^\d+$"
            title="This field only accepts numbers"
            required
          />
        </div>
        <div class="input-field">
          <label for="address">Adresse de la propriété <span class="require-mark" aria-hidden="true">*</span></label>
          <input id="address" v-model="street" name="address" type="text" required />
        </div>

        <div class="input-field">
          <label for="city">Ville <span class="require-mark" aria-hidden="true">*</span></label>
          <input id="city" v-model="city" name="city" type="text" required />
        </div>

        <div class="input-field">
          <label for="state_code"
            >{{ isUs ? 'État' : 'Province' }} <span class="require-mark" aria-hidden="true">*</span>
            <select id="state_code" name="state_code" :disabled="country === ''" v-model="state" required>
              <option value="" selected hidden>
                {{ isUs ? 'Sélectionner un état' : 'Sélectionnez une province' }}
              </option>
              <option v-for="item in StatesProvinceList" :value="item.name.toLowerCase()" :key="item.abbreviation">
                {{ item.name }}
              </option>
            </select>
          </label>
        </div>

        <div class="input-field">
          <label for="country"
            >Pays <span class="require-mark" aria-hidden="true">*</span>
            <select id="country" name="country" v-model="country" required>
              <option value="États-Unis" selected>États-Unis</option>
              <option value="Canada">Canada</option>
            </select>
          </label>
        </div>

        <div class="input-field">
          <label for="zipCode">Code postal <span class="require-mark" aria-hidden="true">*</span></label>

          <input
            v-if="isUs"
            id="zipCode"
            maxlength="10"
            name="zipCode"
            type="text"
            pattern="^\d{5}(-\d{4})?$"
            required
            v-model="zipCode"
          />
          <input
            v-if="!isUs"
            id="zipCode"
            maxlength="7"
            name="zipCode"
            type="text"
            pattern="^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$"
            title="e.g. A1A 1A1"
            required
            v-model="zipCode"
          />
        </div>
        <div class="input-field">
          <label for="like_to">J’aimerais <span class="require-mark" aria-hidden="true">*</span></label>
          <select v-model="interestType" name="like_to" id="like_to" required>
            <option v-for="(item, index) in interestedInOptions" :value="item.value" :key="index">
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="input-field">
          <label for="num_properties"
            >Nombre de propriétés <span class="require-mark" aria-hidden="true">*</span></label
          >
          <select v-model="numberProperties" name="num_properties" id="num_properties" required>
            <option v-for="(item, index) in numberPropertiesOptions" :value="item.value" :key="index">
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="input-field">
          <label for="property_type">Type de propriété <span class="require-mark" aria-hidden="true">*</span></label>
          <select v-model="useCase" name="property_type" id="property_type" required>
            <option v-for="(item, index) in useCaseOptions" :value="item.value" :key="index">{{ item.label }}</option>
          </select>
        </div>
        <div class="input-field">
          <label for="notes">Notes</label>
          <textarea v-model="notes" maxlength="1000" id="notes" name="notes"></textarea>
        </div>
        <div class="input-field checkbox">
          <label for="further-communication" class="checkbox">
            <input
              v-model="OptMeinFutureComm"
              id="further-communication"
              name="further-communication"
              type="checkbox"
              value="true"
            />
            J’accepte de recevoir d’autres renseignements d’Electrify Canada (p. ex. des renseignements sur les
            produits, les promotions et d’autres renseignements), y compris par le biais de communications
            électroniques*.
          </label>
          <p class="form-question">
            *Je sais que je peux me désabonner des communications électroniques à tout moment en cliquant sur se
            désabonner. Cliquez <router-link class="link" :to="{ name: 'privacy-fr' }">ici</router-link> pour consulter
            la Déclaration de confidentialité d’Electrify Canada.
          </p>
        </div>
        <input ref="submitButton" class="button button--submit" name="submit" type="submit" value="envoyer" />
        <p class="references">
          <sup>1</sup>1 https://www.linkedin.com/pulse/do-ev-charging-stations-make-more-loyal-customers-survey-leavitt/
        </p>
      </div>
      <p v-show="serverError !== ''" class="server-error">
        Error:
        <br />
        {{ serverError }}
      </p>
    </form>
  </div>
</template>

<script>
import States from '@/assets/json/us_states.json';
import Provinces from '@/assets/json/ca_provinces.json';
import ApiService from '@/services/Api';
import lang from '@/lang/Forms';

export default {
  name: 'realestate',
  metaInfo: {
    title: 'Héburgez un Site | Electrify Canada',
    meta: [
      {
        name: 'description',
        content: 'Héburgez un Site d’Electrify Canada de Recharge Ultrarapide.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifycanada.com/fr/real-estate/' }],
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      company: null,
      title: null,
      phone: null,
      city: null,
      street: null,
      state: null,
      StatesProvinceList: Provinces.fr,
      country: 'Canada',
      zipCode: null,
      interestedInOptions: [
        {
          label: 'Héberger une station de recharge pour VE sur ma propriété',
          value: 'Host an EV Charging Station on My Property',
        },
        {
          label: 'Propriétaire de mon propre réseau de recharge pour VE',
          value: 'Own My Own EV Charging Network',
        },
        {
          label: 'Demander un nouvel emplacement de recharge',
          value: 'Request a New Charging Location',
        },
        {
          label: 'Autre',
          value: 'Other',
        },
      ],
      interestType: '',
      numberPropertiesOptions: [
        {
          label: '1',
          value: '1',
        },
        { label: '2 à 5', value: '2 to 5' },
        { label: '5+', value: '5+' },
      ],
      numberProperties: '',
      useCaseOptions: [
        { label: 'Commerce de détail', value: 'Retail' },
        { label: 'Restaurant', value: 'Restaurant' },
        { label: 'Hospitalité/hôtel', value: 'Hospitality/Hotel' },
        { label: 'Gouvernement', value: 'Government' },
        { label: 'Dépanneur', value: 'Convenience' },
        { label: 'Lieu de travail', value: 'Workplace' },
        { label: 'Concessionnaire', value: 'Dealership' },
        { label: 'Residential', value: 'Residential' },
        { label: 'Utilisation mixte', value: 'Mixed Use' },
        { label: 'Autre', value: 'Other' },
      ],
      useCase: '',
      timelineOptions: ['As soon as possible', '6 - 12 months', '12-24 months', 'No Preference'],
      notes: null,
      OptMeinFutureComm: null,
      serverError: '',
    };
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
    isTablet() {
      return this.$resize && this.$mq.below(1200);
    },
    isUs() {
      return this.country === 'États-Unis';
    },
  },
  methods: {
    async validate(e) {
      e.preventDefault();
      this.$refs.submitButton.disabled = true;
      this.serverError = '';
      try {
        const params = {
          site: 'ELCA',
          recaptchaToken: await this.recaptcha(),
          body: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            companyName: this.company,
            title: this.title,
            phone: this.phone,
            city: this.city,
            street: this.street,
            state: this.state,
            country: this.country,
            zipcode: this.zipCode,
            interestType: this.interestType,
            numberOfProperties: this.numberProperties,
            useCase: this.useCase,
            notes: this.notes,
            optMeinFutureComm: this.OptMeinFutureComm,
            utm_medium: this.$route.query.utm_medium,
            utm_source: this.$route.query.utm_source,
          },
        };
        this.realEstatePut(params);
      } catch (error) {
        console.error(error);
        this.$refs.submitButton.disabled = false;
      }
    },

    realEstatePut(params) {
      const loader = this.$loading.show();
      this.$store.commit('setEmail', this.email);
      ApiService.init();
      ApiService.put('/v1/salesforce/sitehostcontactus', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: 'real-estate-verification-fr' });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          // Commenting out surfacing Salesforce errors to end users until we've made messaging more robust
          /*
          if (error.response.status === 500) {
            this.serverError = 'There was an error submitting the form, please try again';
          } else {
            this.serverError = error.response.data.errMsg;
          }
          */
          this.serverError = "Une erreur s'est produite lors de l'envoi du formulaire";

          this.$refs.submitButton.disabled = false;
          loader.hide();
          console.error(error);
        });
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
  },
  watch: {
    country(newCountry) {
      switch (newCountry) {
        case 'États-Unis':
          this.StatesProvinceList = States.fr;
          break;
        case 'Canada':
          this.StatesProvinceList = Provinces.fr;
          break;
        default:
          this.StatesProvinceList = [];
      }
      this.state = '';
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style lang="scss" scoped>
.real-estate {
  img {
    width: 100%;
    height: 100%;
  }

  .button.fixed-button {
    border-radius: 8px 0 0 8px;
    border: $c-primary;
    right: 0;
    z-index: 2;
    top: 150px;
    position: fixed;
    max-width: 150px;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 24px 20px 24px 24px;
    text-transform: none;
    &:hover {
      color: $c-primary;
    }
  }

  .container {
    overflow: auto;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .flex {
    display: flex;
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .hero {
    background: url('~@/assets/images/RealEstate/realestate-hero.png') no-repeat;
    background-size: auto;
    background-position-x: center;
    height: 896px;
    color: $c-primary-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 64px;
    @media (max-width: 900px) {
      padding-left: 20px;
      padding-right: 20px;
      min-height: 800px;
    }

    h1 {
      font-size: 64px;
      font-weight: 200;
      line-height: 0.92;
      margin: 0;
      @media (max-width: 900px) {
        font-size: 48px;
      }
    }
    h1 + p {
      font-size: 2rem;
      font-weight: $fw-regular;
      line-height: 38px;
      margin-top: 9px;
      max-width: 1200px;

      @media (max-width: 900px) {
        font-size: 18px;
      }
    }

    h1,
    p {
      text-shadow: 0px 0px 6px black, 0px 0px 12px black;
      color: $c-primary-background;
    }

    .button {
      font-size: 1rem;
      background: linear-gradient(90deg, rgba(53, 189, 209, 1) 50%, rgba(255, 255, 255, 1) 50%);
      color: $c-primary;
      border: none;
      background-position: 100% 0;
      background-size: 202% auto;
      transition: all 300ms ease;
      margin-top: 20px;
      padding: 21px 45px;
      &:hover,
      &:active {
        background-position: 0 0;
      }
      @media (max-width: 450px) {
        margin-top: 0px;
      }
    }
    .flex {
      padding-top: 348px;
      text-align: center;
      justify-content: flex-end;

      p {
        margin: 0 30px;
        max-width: 290px;
        font-weight: $fw-regular;
      }
      @media (max-width: 900px) {
        padding-bottom: 100px;
        padding-top: 200px;

        p {
          margin: 10px 0;
          max-width: 600px;
        }
      }
      @media (max-width: 450px) {
        padding-bottom: 0px;
        padding-top: 44px;
      }
    }
  }

  .body {
    margin: -50px 0 0 0;
    padding: 0;
  }
  .cards-container {
    text-align: center;
    max-width: 988px;
    margin: 0 auto;
    margin-bottom: 120px;
    h2 {
      margin: 0.83em 0;
    }
  }
  .card {
    background-color: $c-secondary-background;
    width: 307px;
    margin-right: 30px;
    border-radius: 8px;
    text-align: left;
    padding: 60px 0 40px 40px;
    img {
      height: auto;
      width: auto;
      padding-bottom: 40px;
    }
    h3 {
      border-top: 3px solid #35bdd1;
      padding-top: 20px;
      max-width: 180px;
      font-size: 25px;
      font-weight: 600;
    }
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 1030px) {
      margin: 30px auto;

      &:last-child {
        margin-right: auto;
      }
    }
  }

  .lede {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:first-of-type {
      margin-bottom: 100px;
    }
    &:last-of-type {
      margin-bottom: 100px;
    }
    a {
      text-decoration: underline;
      font-weight: $fw-medium;
      cursor: pointer;
    }

    h2 {
      max-width: 370px;
      letter-spacing: 0.02em;
      margin-bottom: 1rem;
      padding: 0px 0 35px 0;
      &::after {
        content: '';
        width: 84px;
        border-top: 4px solid #35bdd1;
        display: block;
        border-radius: 5px;
        position: relative;
        top: 34px;
        @media (max-width: 950px) {
          top: 23px;
        }
      }
    }

    p {
      margin: 0;
      margin-bottom: 28px;
      width: 70%;
    }
    ul {
      padding: 0;
    }

    .list-electric-charger {
      li {
        list-style: none;
        background: url('~@/assets/images/RealEstate/electric-icon.svg?external') no-repeat;
        padding-left: 30px;
        line-height: 24px;
        margin: 10px 0;
        width: 70%;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      position: relative;
      padding-left: 5%;
      width: calc(30%);
      justify-content: center;
      &.image {
        width: calc(70%);
        padding: 0;
        align-items: flex-start;
      }
      &.map {
        background-color: $c-secondary-background;
        padding-top: 60px;

        img {
          width: 80%;
        }
        align-items: center;
        text-align: center;
        position: relative;
        span {
          position: relative;
          width: 100%;
        }
        li {
          width: 100%;
          padding: 0 10px;
          box-sizing: border-box;
        }
        li:first-of-type {
          list-style: circle inside url('~@/assets/images/RealEstate/green-dot.svg?external');
        }
        li:last-of-type {
          list-style: circle inside url('~@/assets/images/RealEstate/blue-dot.svg?external');
        }
      }
    }

    &--reverse {
      flex-direction: row-reverse;
    }

    @media screen and (max-width: 950px) {
      align-items: center;
      flex-direction: column;
      margin-bottom: 100px;

      .column {
        width: calc(100vw - 30px);
        align-items: center;
        justify-content: center;
        padding-left: 0;

        p {
          width: calc(100vw - 30px);
          max-width: 400px;
        }
        h2 {
          margin: 0;
          padding-top: 35px;
          max-width: 400px;
          width: calc(100vw - 30px);

          @media (max-width: 950px) {
            br {
              display: none;
            }
          }
          &.short-title {
            width: calc(100vw - 30px);
          }
        }
        &.image {
          width: 100%;
        }
        ul {
          max-width: 400px;
        }
      }
    }
  }

  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
      max-width: 376px;
      padding-bottom: 59px;
      margin-top: 0;
    }

    section {
      width: 40%;
      max-width: 400px;
      margin-left: 100px;
      @media (max-width: 1200px) {
        margin-left: 0;
      }
      p {
        &::before {
          content: '';
          width: 84px;
          border-top: 4px solid #35bdd1;
          display: block;
          border-radius: 5px;
          position: relative;
          bottom: 10px;
        }
      }
    }
    .form-wrap {
      width: 60%;
      padding-bottom: 80px;
      padding-top: 22px;
      margin-left: 100px;
      @media (max-width: 1200px) {
        margin-left: 0;
      }
    }

    .form-question {
      color: $c-text;
      font-weight: normal;
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }
    p.form-question:first-of-type {
      margin-top: 16px;
    }

    h2,
    p {
      color: $c-primary;
    }

    .input-field {
      margin-bottom: 29px;
      position: relative;
    }

    input,
    textarea,
    select,
    .input-field .checkbox,
    .form-question {
      transition: 500ms ease;
      box-sizing: border-box;
      width: 600px;

      @media (max-width: 950px) {
        width: calc(100vw - 50px);
      }
    }

    input[type='submit'] {
      width: 150px;
      margin-bottom: 50px;
    }

    input[type='checkbox'] {
      width: 16px;
      height: 16px;
      margin-bottom: 10px;
    }

    .references {
      font-size: 10px;
      margin: 4px 0;
      letter-spacing: 1.61;
      sup {
        font-size: 6px;
      }
    }
    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      .form-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100vw - 50px);
      }
      section {
        max-width: 100%;
        width: calc(100vw - 30px);
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
          max-width: 600px;
          margin: 0;
        }
        p {
          text-align: left;
        }
      }
    }
  }
}
</style>
